exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bigdata-analytics-platform-products-js": () => import("./../../../src/pages/bigdata_analytics_platform_products.js" /* webpackChunkName: "component---src-pages-bigdata-analytics-platform-products-js" */),
  "component---src-pages-blogs-cloud-based-generative-ai-10-ways-to-transform-business-js": () => import("./../../../src/pages/blogs/cloud-based-generative-ai-10-ways-to-transform-business.js" /* webpackChunkName: "component---src-pages-blogs-cloud-based-generative-ai-10-ways-to-transform-business-js" */),
  "component---src-pages-blogs-elastic-search-to-s-3-data-migration-js": () => import("./../../../src/pages/blogs/elastic-search-to-s3-data-migration.js" /* webpackChunkName: "component---src-pages-blogs-elastic-search-to-s-3-data-migration-js" */),
  "component---src-pages-blogs-gen-ai-adoption-playbook-framework-js": () => import("./../../../src/pages/blogs/gen_ai_adoption_playbook_framework.js" /* webpackChunkName: "component---src-pages-blogs-gen-ai-adoption-playbook-framework-js" */),
  "component---src-pages-blogs-genai-adoption-mitigating-risks-js": () => import("./../../../src/pages/blogs/genai-adoption-mitigating-risks.js" /* webpackChunkName: "component---src-pages-blogs-genai-adoption-mitigating-risks-js" */),
  "component---src-pages-blogs-the-data-quality-jigsaw-js": () => import("./../../../src/pages/blogs/the-data-quality-jigsaw.js" /* webpackChunkName: "component---src-pages-blogs-the-data-quality-jigsaw-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case_studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-cdo-handbook-7-ways-to-cut-down-data-costs-js": () => import("./../../../src/pages/cdo-handbook-7-ways-to-cut-down-data-costs.js" /* webpackChunkName: "component---src-pages-cdo-handbook-7-ways-to-cut-down-data-costs-js" */),
  "component---src-pages-cloud-transformation-js": () => import("./../../../src/pages/cloud_transformation.js" /* webpackChunkName: "component---src-pages-cloud-transformation-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom_software_development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-fullstack-engineer-js": () => import("./../../../src/pages/jobs/fullstack-engineer.js" /* webpackChunkName: "component---src-pages-jobs-fullstack-engineer-js" */),
  "component---src-pages-jobs-fullstack-intern-js": () => import("./../../../src/pages/jobs/fullstack-intern.js" /* webpackChunkName: "component---src-pages-jobs-fullstack-intern-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-development-js": () => import("./../../../src/pages/product_development.js" /* webpackChunkName: "component---src-pages-product-development-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who_we_are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/case-studies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-job-details-js": () => import("./../../../src/templates/job-details.js" /* webpackChunkName: "component---src-templates-job-details-js" */)
}

